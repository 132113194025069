export const prices = [
    {
        _id: 0,
        name: 'Default',
        array: []
    },
    {
        _id: 1,
        name: '$0 to $9',
        array: [0, 9]
    },
    {
        _id: 2,
        name: '$10 to $19',
        array: [10, 19]
    },
    {
        _id: 3,
        name: '$20 to $29',
        array: [20, 29]
    },

    {
        _id: 4,
        name: '$30 to $39',
        array: [30, 30]
    },
    {
        _id: 5,
        name: 'More than $40',
        array: [40, 99]
    }
]