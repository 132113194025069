import React, {useState, useEffect} from 'react';
import Layout from './Layout';
import {Link} from 'react-router-dom';
import {getProducts, getBraintreeClientToken, processPayment, createOrder} from './apiCore';
import {emptyCart} from './cartHelpers';
import Card from './Card';
import {isAuthenticated} from '../auth';
import DropIn from "braintree-web-drop-in-react";

const Checkout = ({products}) => {
    const [data, setData] = useState({
        loading: false,
        success: false,
        clientToken: null,
        error: '',
        instance: {},
        address: ''
    });

    const userId = isAuthenticated() && isAuthenticated().user._id;
    const token = isAuthenticated() && isAuthenticated().token;

    const getToken = (userId, token) => {
        getBraintreeClientToken(userId, token).then(data => {
            if(data.error){
                setData({...data, error: data.error});
            } else {
                setData({clientToken: data.clientToken});
            }
        });
    }

    useEffect(() => {
        getToken(userId, token);
    }, [])

    const handleAddress = event => {
        setData({...data, address: event.target.value});
    }

    const getTotal = () => {
        return products.reduce((currentValue, nextValue) => {
            return currentValue + nextValue.count * nextValue.price;
        }, 0);
        
    }

    const showCheckout = () => {
        return isAuthenticated() ? (
            <div>{showDropIn()}</div>
        ) : (
            <div>
            <h3>Login so you can pay!</h3>
            <Link to='/signin' className='btn btn-success'>Login</Link>
            </div>
        )
    }

    let deliveryAddress = data.address;

    const buy = () => {
        setData({loading: true});
        // send nonce to the server
        // nonce = data.instance.requestPaymentMethod()

        let nonce;
        let getNonce = data.instance.requestPaymentMethod()
        .then(data => {
            // console.log(data);
            nonce = data.nonce;
            // once you have nonce (cardType, cardNumber) send nonce as 'PaymentMethodNonce'
            // and also total to be charged

            // console.log(`send nonce and total to process: ${nonce}, ${getTotal(products)}`);
            const paymentData = {
                paymentMethodNonce: nonce,
                amount: getTotal(products)
            }

            processPayment(userId, token, paymentData).then(response => {
                // console.log(response);
                // create the order
                const createOrderData = {
                    products,
                    transaction_id: response.transaction_id,
                    amount: response.transaction.amount,
                    address: deliveryAddress
                }
                createOrder(userId, token, createOrderData)
                setData({...data, success: response.success});
                
                // empty cart 
                emptyCart(() => {
                    console.log('payment success and empty cart!')
                    setData({loading: false, success: true});
                })
                
            }).catch(error => {
                console.log(error);
            })

        }).catch(err => {
            // console.log(`dropin error: ${err}`);
            setData({...data, error: err.message});
        })
    }

    const showDropIn = () => (
        <div onBlur={() => setData({...data, error: ''})}>
            {data.clientToken !== null && products.length > 0 ? (
                <div>
                    <div className='form-group mb-3'>
                        <label className='text-muted'>Delivery address:</label>
                        <textarea onChange={handleAddress} className='form-control' value={data.address} placeholder='Type your delivery address here...'></textarea>
                    </div>
                    <DropIn options={{
                        authorization: data.clientToken,
                        paypal: {
                            flow: 'vault'
                        }
                    }} onInstance={instance => data.instance = instance} />
                    <button onClick={buy} className='btn btn-block btn-success'>Pay</button>
                </div>
            ) : null}
        </div>
    )

    const showError = error => (
        <div className='alert alert-danger' style={{display: error ? '' : 'none'}}>
            {error}
        </div>
    )

    const showSuccess = success => (
        <div className='alert alert-success' style={{display: success ? '' : 'none'}}>
            Thanks! Your payment was successful
        </div>
    )

    const showLoading = loading => (
        loading && <h2 className='alert alert-info'>Loading...</h2>
    )

    return (
        <div>
            <h2>Total: ${getTotal()}</h2>
            {showLoading(data.loading)}
            {showSuccess(data.success)}
            {showError(data.error)}
            {showCheckout()}
            
        </div>
    )
}

export default Checkout;