import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Layout from '../core/Layout';
import {isAuthenticated} from '../auth';
import {read, update, updateUser} from './apiUser';
import {Redirect} from 'react-router-dom';

const Profile = ({match}) => {
    const [values, setValues] = useState({
        name: '',
        email: '',
        password: '',
        error: '',
        success: false
    });
	
    const {name, email, password, error, success} = values;

    const {token} = isAuthenticated();

    const init = (userId) => {
        // console.log(userId);
        read(userId, token).then(data => {
            if(data.error){
                setValues({...values, error: true});
            } else {
                setValues({...values, name: data.name, email: data.email});
            }
        })
    }

    useEffect(() => {
        init(match.params.userId);
    }, [])

    const handleChange = name => e => {
        setValues({...values, error: false, [name]: e.target.value})
    }

    const clickSubmit = e => {
        e.preventDefault();
        update(match.params.userId, token , {name, email, password}).then(data => {
            if(data.error){
                console.log(data.error);
            } else {
                updateUser(data, () => {
                    setValues({...values, name: data.name, email: data.email, success: true})
                })
            }
        })
    }

    const redirectUser = success => {
        if(success){
            return <Redirect to='/' />
        }
    }

    const profileUpdate = (name, email, password) => (
        <form>
            <div className='form-group'>
                <label className='text-muted'>Name</label>
                <input type='text' onChange={handleChange('name')} value={name} className='form-control' />
            </div>
            <div className='form-group'>
                <label className='text-muted'>Email</label>
                <input type='email' onChange={handleChange('email')} value={email} className='form-control' />
            </div>
            <div className='form-group'>
                <label className='text-muted'>Password</label>
                <input type='password' onChange={handleChange('password')} value={password} className='form-control' />
            </div>
            <button onClick={clickSubmit} className='btn btn-primary'>Update</button>
        </form>
    )

	return (
		<Layout title='Profile Update' description= 'Update your profile' className='container-fluid'>
			<h1 className='mb-4'>Profile Update</h1>
            {/* {JSON.stringify(values)} */}
            {profileUpdate(name, email, password)}
            {redirectUser(success)}
		</Layout>
		)
}

export default Profile;